import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';



export default class TimeUntill extends Component {

    state = {
        deadline: 'Feb, 2, 2020',
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0'
    }

    getTimeUntill = (deadline) =>{
        const time = Date.parse(deadline) - Date.parse(new Date())
        if(time > 0){
            const seconds = Math.floor((time/1000) % 60);
            const minutes = Math.floor((time/1000/60) % 60)
            const hours = Math.floor((time/(1000*60*60)%24));
            const days = Math.floor(time/(1000*60*60*24));

            this.setState({days, hours, minutes, seconds})
        }else{

        }
    }

    componentDidMount(){
        setInterval(()=> this.getTimeUntill(this.state.deadline), 1000)
    }

    render() {
        return (
            <Slide left delay={1000}>
                <div className="countdown_wrapper">
                    <div className="countdown_top">Event starts in </div>
                    <div className="countdown_bottom">
                        <div className="countdown_item">
                            <div className="countdown_time">
                                {this.state.days}
                            </div>
                            <div className="tag">
                                Days
                            </div>
                        </div>
                        <div className="countdown_item">
                            <div className="countdown_time">
                                {this.state.hours}
                            </div>
                            <div className="tag">
                                Hrs
                            </div>
                        </div>
                        <div className="countdown_item">
                            <div className="countdown_time">
                                {this.state.minutes}
                            </div>
                            <div className="tag">
                                Mins
                            </div>
                        </div>
                        <div className="countdown_item">
                            <div className="countdown_time">
                                {this.state.seconds}
                            </div>
                            <div className="tag">
                                Secs
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        )
    }
}
