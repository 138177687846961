import React from 'react'
import Fade from 'react-reveal/Fade'


export default function Description() {
  return (
    <Fade>
      <div className="center_wrapper">
        <h2>Highlights</h2>
        <div className="highlight_description">
          <p>
            For Credibility aims to give upcomming artists and entertainers a chance to showcase their talent and skill. We do this through forging partnership with some of the best managers in the industry. The winner of each segment gets the opportunity to work and be mentored under one of the best managers in their industry. Participants who get the highest amount of votes on our mobile app are decleared winners at the end of the show.
          </p>
          <p>
            This year we will be at the Zanku music festival taking place at THE Hotel Enugu. Tickets are limited so hurry and register.
          </p>

          <p>
            Now is the timefor you to go mainstream, singup for this event today the world is waiting.
          </p>

            </div>
      </div>

    </Fade>
  )
}
