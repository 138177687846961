import React from 'react'
import Fade from 'react-reveal/Fade'


export default function Footer() {
  return (
    <footer className="bck_red">
        <Fade delay={500}>
            <div className="font_righteous footer_logo_venu">For Credibility</div>
            <div className="footer_copyright">
            Bringing you to the limelight
            </div>
        </Fade>
    </footer>
  ) 
}
