import React from 'react'

export default function Index() {
  return (
    <div className="location_wrapper">
        
        <iframe title="Map of venue" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15858.306980153957!2d7.520696414716458!3d6.448348895331792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a6aad4e9ae61%3A0xc870cd29a3a3914a!2sThe+Hotel!5e0!3m2!1sen!2suk!4v1556899544010!5m2!1sen!2suk" width="100%" height="500" frameborder="0"  allowfullscreen></iframe>
        <div className="location_tag">
            <div>Location</div>
        </div>
    </div>
  )
}
